import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import ReturnToSelfCharacters from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Lock from '@material-ui/icons/Lock';
import Popper from '@material-ui/core/Popper';
import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import UpstreamInProgress from '@material-ui/icons/CloudUpload';
import UpstreamSucceeded from '@material-ui/icons/CloudDone';
import UpstreamFailed from '@material-ui/icons/Warning';
import NewCharacter from './NewCharacter/Container';
import DebugReset from './DebugReset/Container';
import CharacterName from './CharacterName/Container';
import ApprovedInfo from './ApprovedInfo';
import AdminAccessInfo from './AdminAccessInfo';
import StagedInfo from './StagedInfo';
import styles from './CharacterSelector.module.scss';
import { hasValidToken } from '../../utils/token';
import {
  isImpersonating,
  isEditable as hasEditPrivilege,
  isPrivileged as hasPrivilege,
} from '../../utils/user';
import { isRemote as hasRemotePersistance } from '../../utils/character';
import history from '../../history';

const PLAYABLE_SORT_ORDER = {
  active: 0,
  staged: 1,
  retired: 2,
  inactive: 3,
};

const Container = () => {
  const dispatch = useDispatch();
  const popperName = 'characterSelector';
  const overlayRef = React.useRef(null);
  const {
    authConfig,
    currentCharacterID,
    characterStorage,
    upstreamSaveState,
    popper,
    approvedForPlay,
    user,
  } = useSelector(state => ({
    authConfig: state.user.session,
    currentCharacterID: state.localStorage.currentCharacterID,
    characterStorage: state.localStorage.characterStorage,
    upstreamSaveState: state.ui.remote.upstreamSaveState,
    popper: state.ui.character.popperOpened,
    approvedForPlay: state.character.approvedForPlay,
    user: state.user,
  }));
  const isRemote = characterStorage
    ? hasRemotePersistance(characterStorage[currentCharacterID])
    : false;
  const isEditable = hasEditPrivilege(user, approvedForPlay);
  const isPrivileged = hasPrivilege(user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;
  const unImpersonate = () => {
    history.push('/');
    dispatch({
      type: 'IMPERSONATE_USER',
      payload: {
        userID: user.id,
        isImpersonating: false,
      },
    });
  };
  const handleChange = (uuid, name, remoteID) => {
    dispatch({
      type: 'RENAME_CHARACTER',
      payload: { uuid, name, remoteID },
    });
  };
  const handleSwitch = uuid => {
    const payload = {
      id: uuid,
      newState: characterStorage[uuid],
    };
    dispatch({ type: 'SWITCH_CHARACTER', payload });
  };
  const handleDelete = uuid =>
    dispatch({ type: 'DELETE_CHARACTER', payload: uuid });
  const handleUndelete = uuid =>
    dispatch({ type: 'UNDELETE_CHARACTER', payload: uuid });
  const handleNewCharacter = () => dispatch({ type: 'CREATE_CHARACTER' });
  const handleClick = event =>
    dispatch({ type: 'TOGGLE_POPPER', payload: popperName });
  const handleLogin = () => {
    dispatch({ type: 'TOGGLE_POPPER', payload: 'loginModal' });
    dispatch({ type: 'LOAD_MODAL', payload: { username: '', password: '' } });
  };
  const retrySaveUpstream = () => dispatch({ type: 'RETRY_SAVE_UPSTREAM' });
  const characterLister = () => {
    const componentMaker = uuid => (
      <CharacterName
        key={uuid}
        name={characterStorage[uuid].name}
        state={characterStorage[uuid].remoteStatus}
        passChange={handleChange}
        passSwitch={handleSwitch}
        passDelete={handleDelete}
        passUndelete={handleUndelete}
        uuid={uuid}
        currentCharacterID={currentCharacterID}
        existance={characterStorage[uuid].state}
        remoteID={characterStorage[uuid].remoteID}
        totalBuild={characterStorage[uuid].totalBuild}
        isEditable={hasEditPrivilege(
          user,
          characterStorage[uuid].approvedForPlay,
        )}
        hasValidToken={hasValidToken(authConfig)}
      />
    );

    const marker = type => (
      <div key={type} className={styles.marker}>
        <span className={styles.text}>
          {type === 'online' && isImpersonating(user)
            ? ` #${user.impersonatee.id} (${[
                user.impersonatee.firstName,
                user.impersonatee.lastName,
              ]
                .join(' ')
                .trim()})`
            : `${type} characters`}
        </span>
        <hr className={styles.divider} />
      </div>
    );
    const onlineCharacters = Object.keys(characterStorage)
      .filter(x => characterStorage[x].remoteID !== null)
      .filter(x => characterStorage[x].remoteStatus !== 'experimental')
      .sort(
        (a, b) =>
          PLAYABLE_SORT_ORDER[characterStorage[a].remoteStatus] -
            PLAYABLE_SORT_ORDER[characterStorage[b].remoteStatus] ||
          characterStorage[a].remoteID - characterStorage[b].remoteID,
      )
      .map(uuid => componentMaker(uuid));

    const noOnlineCharacters = () => (
      <div key='nada' className={styles.nada}>
        No characters found
      </div>
    );

    const returnToSelfCharacters = () => {
      if (!isPrivileged || !isImpersonating(user)) return null;

      return (
        <div
          key='unimpersonate'
          className={styles.button}
          onClick={unImpersonate}
        >
          <ReturnToSelfCharacters className={styles.icon} />
          Return to My Characters
        </div>
      );
    };

    const loginToView = () => (
      <div key='loginToView' className={styles.login} onClick={handleLogin}>
        <Lock className={styles.svg} />
        Login to View
      </div>
    );

    const offlineCharacters = Object.keys(characterStorage)
      .filter(x => characterStorage[x].remoteID === null)
      .map(uuid => componentMaker(uuid));

    const topSide = hasValidToken(authConfig)
      ? [marker('online')]
          .concat(
            onlineCharacters.length === 0
              ? noOnlineCharacters()
              : onlineCharacters,
          )
          //.concat(adminCreateCharacter())
          .concat(returnToSelfCharacters())
      : [marker('online')].concat(loginToView());

    return topSide.concat(marker('offline'), offlineCharacters);
  };

  const highlightedCharacterName = () => {
    if (!characterStorage) return '...';
    if (currentCharacterID in characterStorage)
      return characterStorage[currentCharacterID].name;
    return '...';
  };

  const renderUpstreamSaveState = () => {
    switch (upstreamSaveState) {
      case 'inProgress':
        return <UpstreamInProgress className={styles.blinking} />;
      case 'succeeded':
        return <UpstreamSucceeded />;
      case 'failed':
        return (
          <UpstreamFailed
            styles={{ cursor: 'pointer' }}
            onClick={retrySaveUpstream}
            title='Retry Save'
          />
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    setAnchorEl(popper === popperName ? overlayRef.current : null);
  }, [popper, overlayRef, setAnchorEl]);

  return (
    <div className={styles.characterSelector}>
      <div className={styles.upstreamState}>{renderUpstreamSaveState()}</div>
      <AdminAccessInfo
        isAdminMode={isPrivileged}
        isRemote={isRemote}
        popper={popper}
        hasValidToken={hasValidToken(authConfig)}
      />
      <StagedInfo
        isAdminMode={isPrivileged}
        isRemote={isRemote}
        popper={popper}
        hasValidToken={hasValidToken(authConfig)}
        characterData={characterStorage && characterStorage[currentCharacterID]}
      />
      <ApprovedInfo isEditable={isEditable} popper={popper} />
      <Button onClick={handleClick} ref={overlayRef}>
        {highlightedCharacterName()}
        <ExpandLess
          className={[styles.dropdown, open ? '' : styles.expandHidden].join(
            ' ',
          )}
        />
        <ExpandMore
          className={[styles.dropdown, open ? styles.expandHidden : ''].join(
            ' ',
          )}
        />
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        className={styles.overlayContainer}
        transition
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} timeout={300}>
            <div className={styles.overlay}>
              <Scrollbars
                renderTrackHorizontal={props => (
                  <div {...props} style={{ display: 'none' }} />
                )}
                renderView={props => (
                  <div {...props} className={styles.noHorizontalScroll} />
                )}
                renderThumbVertical={({ style, ...props }) => {
                  return (
                    <div
                      {...props}
                      style={{
                        ...style,
                        backgroundColor: '#ccc',
                        borderRadius: '8px',
                      }}
                    />
                  );
                }}
                autoHeight
                autoHeightMax='67vh'
                autoHide
              >
                {characterLister()}
                <NewCharacter handleNewCharacter={handleNewCharacter} />
                <hr className={styles.divider} />
                <DebugReset />
              </Scrollbars>
            </div>
          </Slide>
        )}
      </Popper>
    </div>
  );
};

export default React.memo(Container);
